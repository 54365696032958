import React, { useState, useEffect } from 'react';
import { MessageSquare, ArrowRight, Star, Users, Zap } from 'lucide-react';

const Flag = ({ country }) => {
  switch (country) {
    case 'uk':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30" width="32" height="24">
          <clipPath id="t">
            <path d="M30,15 h30 v15 z v15 h-30 z h-30 v-15 z v-15 h30 z"/>
          </clipPath>
          <path d="M0,0 v30 h60 v-30 z" fill="#00247d"/>
          <path d="M0,0 L60,30 M60,0 L0,30" stroke="#fff" strokeWidth="6"/>
          <path d="M0,0 L60,30 M60,0 L0,30" clipPath="url(#t)" stroke="#cf142b" strokeWidth="4"/>
          <path d="M30,0 v30 M0,15 h60" stroke="#fff" strokeWidth="10"/>
          <path d="M30,0 v30 M0,15 h60" stroke="#cf142b" strokeWidth="6"/>
        </svg>
      );
    case 'brazil':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 720 504" width="32" height="24">
          <path fill="#009b3a" d="M0 0h720v504H0z"/>
          <path fill="#fedf00" d="M360 252l204.4 157.3-78-241.4-78 241.4L612.8 252 360 94.7 107.2 252l204.4 157.3-78-241.4-78 241.4L360 252z"/>
          <circle fill="#002776" cx="360" cy="252" r="110"/>
          <path fill="#fff" d="M360 341c-17 0-33-5-47-14 28 8 58 8 86 0a89 89 0 01-39 14z"/>
        </svg>
      );
    case 'spain':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 750 500" width="32" height="24">
          <rect width="750" height="500" fill="#c60b1e"/>
          <rect width="750" height="250" y="125" fill="#ffc400"/>
        </svg>
      );
    default:
      return null;
  }
};

const FeatureCard = ({ Icon, title, description }) => (
  <div className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-lg p-4 flex flex-col items-center text-center">
    <Icon size={24} className="mb-2 text-yellow-400" />
    <h3 className="font-bold mb-1">{title}</h3>
    <p className="text-sm">{description}</p>
  </div>
);

const RedirectPage = () => {
  const [countdown, setCountdown] = useState(10);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          window.location.href = "https://cmos.ai";
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="flex flex-col items-center justify-between min-h-screen bg-gradient-to-br from-indigo-600 via-purple-600 to-pink-500 text-white p-4">
      <div className="w-full flex justify-between items-center mb-4">
        <div className="text-sm font-bold">CMOS AI</div>
        <div className="flex space-x-2">
          <Flag country="uk" />
          <Flag country="brazil" />
          <Flag country="spain" />
        </div>
      </div>

      <div className="bg-white text-gray-800 rounded-lg shadow-2xl p-8 max-w-md w-full transform transition-all duration-500 ease-in-out hover:scale-105">
        <div className="flex items-center justify-center mb-6 animate-pulse">
          <div className="relative">
            <MessageSquare size={48} className="text-indigo-600" />
            <span className="absolute bottom-0 right-0 text-2xl">💬</span>
          </div>
          <h1 className="text-3xl font-bold ml-3 bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 to-pink-500">
            Marketing.Chat
          </h1>
        </div>
        <p className="text-lg mb-6 text-center leading-relaxed">
          Descubra o poder da IA no marketing com o CMOS AI.
          Otimize suas estratégias e aumente seus resultados agora!
        </p>
        <div className="flex flex-col items-center">
          <a
            href="https://cmos.ai"
            className={`group bg-gradient-to-r from-indigo-600 to-pink-500 text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg flex items-center ${isHovered ? 'animate-bounce' : ''}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            Potencialize seu Marketing com IA
            <ArrowRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
          </a>
          <p className="mt-4 text-sm text-gray-600 animate-fade-in">
            Redirecionando em <span className="font-bold text-indigo-600">{countdown}</span> segundos...
          </p>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4 mt-8 w-full max-w-2xl">
        <FeatureCard
          Icon={Star}
          title="IA Avançada"
          description="Acesso a modelos de ponta como GPT-4 e Claude"
        />
        <FeatureCard
          Icon={Users}
          title="Personalização"
          description="Adapte-se ao seu estilo e necessidades únicas"
        />
        <FeatureCard
          Icon={Zap}
          title="Eficiência"
          description="Otimize suas estratégias em tempo recorde"
        />
      </div>

      <div className="mt-8 text-sm text-white opacity-75">
        Powered by The CMOs Marketers
      </div>
    </div>
  );
};

export default RedirectPage;